import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import SEO from "../components/seo"


const Referenzen = () => (
  <Layout>
    <div class="page-narrow">
      
      <h1 className="heading heading--h1" hidden>
        Referenzen
      </h1>

      <h3 className="ref-year">2024</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Nächste Ausfahrt Glück</span> P: Producers at
          Work
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Allein unter Müttern</span>
          P: Producers at Work
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Any Other Night</span> R: Michiel ten Horn P: One
          Two Films
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title"> Familienfedern</span> P: Oma Inge Film
        </li>
        <li className="ref-list__list-item"></li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title"> Cavagai</span> P: Sutor Kolonko
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Helix
          </span>
          P: Enigmafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Palast 2</span> P: Moovie
        </li>
        <li className=" ref-list__list-item">
          <span className="ref-list__film-title">The Lights, They Fall</span> P: Vajda Film
        </li>
        <li className="ref-list__list-item"></li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title"> Herbertstraße</span> P: Madeforfilms
        </li>
      </ul>
      <h3 className="ref-year">2023</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Fall Marianne Voss</span> P:
          Senator Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Public Affairs</span>
          P: Isarstraßen Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Krank</span> P: Real Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Bad Influencer</span> P: TELLUX
        </li>
        <li className="ref-list__list-item"></li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Polizeiseelsorger</span> P: Ufa
          Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            The End
          </span> P: Producers at work
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Im Nebel</span> P: Hager Moss
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Doppelhaushälfte</span> P: Iconoclast
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Zweiflers</span> P: Turbokultur
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Lichtblick</span>P: Kanufilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Quartett</span> P: Akzente Film
        </li>
      </ul>
      <h3 className="ref-year">2022</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item"><span className="ref-list__film-title">Die
          Jägerin</span> P:
          Real Film</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Miss
          Pirie and Miss
          Woods</span> P: Heimatfilm</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Die
          geschützten
          Männer</span> P: Filmgalerie 451</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Ein
          starkes Team</span>
          P: Ufa Fiction</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Verbrannte
          Erde</span> P:
          Schramm Film</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Die Macht
          der
          Frauen</span> R: Lars Becker P: Network Movie</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Schlafende
          Hunde</span>
          P: Real Film </li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Das
          bleibt unter
          uns</span> P: Network Movie</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Lamia</span> P:
          Turbokultur</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Berlin
          Nobody</span> P:
          Augenschein Filmproduktion</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Tatort:
          Das Opfer</span>
          P: Geißendörfer Pictures</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Pauline</span> P:
          btf
          Series</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Tender
          Hearts</span> P:
          Odeon Fiction</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Die
          Herrlichkeit des
          Lebens R: Georg Maas</span> P: Tempest Film</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Deadlines</span>
          P:
          Turbokultur</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Usedom
          Krimi</span> P:
          Razor Filmproduktion</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">FC
          Stasi</span> P: Corso
          Film</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Gott der
          bösen
          Tiere</span> P: Missing Link Films</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Suburra</span> P:
          Cattleya </li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Asbest:</span> P:
          Pantaleon Films</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">C’est la
          vie</span> P:
          Ufa Fiction</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Everyone
          is f*cking
          crazy</span> P: Real Film</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Die
          Discounter</span> P:
          Pyjama Pictures</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Schöne
          Bescherung</span>
          P: Odeon Fiction</li>
        <li className="ref-list__list-item"><span className="ref-list__film-title">Sam – Ein
          Sachse</span>
          P: Ufa Fiction</li>
      </ul>




      <h3 className="ref-year">2021</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Überfall</span> R: Stephan
          Lacant P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Känguru-Verschwörung</span>{" "}
          R: Marc-Uwe Kling P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Stella</span> P: Studio Hamburg
          Gruppe
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Eine iranische Frau</span> R:
          Steffi Niederzoll
        </li>
        <li className="ref-list__list-item"></li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Glücklichen</span> P:
          Schramm Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Das Leben ist kein Kindergarten
          </span>{" "}
          P: Amusement Park
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Gesang des Raben</span> P:
          Zum Goldenen Lamm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Oh Hell</span> P: Good Friends
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Rumspringa</span> P: Constantin
          Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Doktor Ballouz</span> P:
          X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Buba</span> R: Arne Feldhusen
          P: btf
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">All You Need</span> P: UFA
          Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Macht der Frauen</span> R:
          Lars Becker P: Network Movie
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Palast</span> P: Constantin
          Television
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Piaffe</span> P: Schuldenberg
          Films
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ze Network</span> P: Syrreal
          Dogs
        </li>
      </ul>
      <h3 className="ref-year">2020</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schneller als die Angst</span>{" "}
          R: Florian Baxmeyer P: Rowboat Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tatort - Die dritte Haut</span>{" "}
          P: StudioTV
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Para</span> P: Wiedemann & Berg
          Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">The Mopes</span> R: Christian
          Zübert P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tina Mobil</span> P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die letzte Spur Berlin</span>{" "}
          P: Novafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Blackout</span> P: Wiedemann &
          Berg Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Furia</span> P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Bring mich nach Hause</span> P:
          Rowboat Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Nach eigenem Gesetz</span> P:
          Real Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Kolleginnen - Das böse Kind
          </span>{" "}
          P: Real Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Kold</span> P: Missing Link
          Films
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Little Amerika</span> P: FFP
          New Media
        </li>
      </ul>
      <h3 className="ref-year">2019</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Undine</span> R: Christian
          Petzold P: Schramm Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Klandestin </span> R: Angelina
          Maccarone P: Calafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der gute Bulle 3</span> R: Lars
          Becker P: Network Movie
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Berthold Beitz – Ein unruhiges Leben
          </span>{" "}
          R: Dror Zahavi P: Zeitsprung
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ella Schön</span> P: Dreamtool
          Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Europe</span> R: Philip
          Scheffner P: Pong / Haute les mains
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schule fürs Leben</span> P:
          Polyphon
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das verlassene Dorf</span> R:
          Christiane Balthasar P: Wiedemann &#38; Berg
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Heiland</span> P: Olga Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title"> Torn</span> R: Florian Gärtner
          P: ITV Studios Germany
        </li>
      </ul>
      <h3 className="ref-year">2018</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Dead End</span> R: Christopher
          Schier P: Real Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Was gewesen wäre</span> R:
          Florian Koerner v. Gusdorf P: Flare Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110: Zehn Rosen
          </span>{" "}
          R: Torsten C. Fischer P: Filmpool Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Golden Twenties</span> R:
          Sophia Kluge P: Amerikafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Arthurs Gesetz</span> R:
          Christian Zübert P: Good Friends
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Jägerin</span> R: Andreas
          Herzog P: Real Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Die Spezialisten – Im Namen der Opfer
          </span>{" "}
          P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Dengler – Brennende Kälte
          </span>{" "}
          R: Rick Ostermann P: Bavaria
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Fall Collini</span> R:
          Marco Kreuzpaintner P: Constantin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Bulle und das Biest</span>{" "}
          P: Bantry Bay Productions
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tödliche Geheimnisse 3</span>{" "}
          R: Barbara Kulcsar P. Wiedemann &#38; Berg
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Check Check</span> R: Lars
          Jessen P: Bird &#38; Bird Film
        </li>
      </ul>
      <h3 className="ref-year">2017</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Deutschland 86</span> R: Arne
          Feldhusen P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Lara</span> R: Jan-Ole Gerster
          P: Schiwago Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Arthurs Gesetz</span> R:
          Christian Zübert P: Good Friends
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Kudamm 59</span> R: Sven Bohse
          P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110: Das Beste für mein Kind
          </span>{" "}
          R: Jakob Ziemnicki P: DOKfilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schattengrund</span> R: Dror
          Zahavi P: Constantin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Tatort: Tiere der Großstadt
          </span>{" "}
          R: Roland Suso Richter P: Provobis
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Joshua Profil</span> R:
          Jochen Alexander Freydank P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ella Schön</span> R: Maurice
          Hübner P: Dreamtool Entertainment
        </li>
      </ul>
      <h3 className="ref-year">2016</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tod im Internat</span> R:
          Torsten C. Fischer P: TV60 München
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Hanni und Nanni – Sowas von neu
          </span>{" "}
          R: Isabel Suba P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Mein sind deine Kinder</span>{" "}
          R: Marco Kreuzpaintner P: ndF Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Sommer meines Lebens</span>{" "}
          P: Dreamtool Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das goldene Jahr</span> R:
          David Dietl P: TV60 München
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">SOKO Wismar</span> diverse
          Folgen P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Teufelslmoor</span> P: Brigitte
          Bertele P: Real Film/Nordfilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Krieg</span> R: Rick Ostermann
          P: Schiwago Film
        </li>
      </ul>
      <h3 className="ref-year">2015</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tschick</span> R: Fatih Akin P:
          Lago Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ku’damm 56</span> R: Sven Bohse
          P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">SMS für Dich</span> R: Karoline
          Herfurth P: Warner Bros.
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Berlin Syndrom</span> R: Cate
          Shortland P: Aquarius Films u.a.
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Wie ich dich liebe, Rätselleben
          </span>{" "}
          R: Cordula Kablitz-Post P: Avanti Media Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Mängelexemplar</span> R: Laura
          Lackmann P: UFA Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Marie Curie</span> R: Maie
          Noelle P: P'Artisan Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Begierde – Jäger in der Nacht
          </span>{" "}
          R: Brigitte Bertele P: Zeitsprung Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tatort – Ätzend</span> R: Dror
          Zahavi P: Wiedemann &#38; Berg
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Nur nicht aufregen!</span> R:
          Thomas Jahn P: Warner Bros. ITVP Deutschland GmbH
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110 – Der Preis der Freiheit
          </span>{" "}
          R: Stephan Rick P: RealFilm Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">SOKO Wismar</span> P:
          Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Unsichtbaren</span> R:
          Claus Räfle P: Look Film / Cineplus
        </li>
      </ul>
      <h3 className="ref-year">2014</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der weiße Fleck</span> R: Elmar
          Fischer P: Enigma Fimproduktiont
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Die dunkle Seite des Mondes
          </span>{" "}
          R: Stephan Pick P: Port au Prince
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Engel der Gerechtigkeit</span>{" "}
          R: Sigi Rothemund P: Polyphon
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Deutschland 83 (8-teilige Serie)
          </span>{" "}
          R: Edward Berger, Samira Radsi P: Ufa Fiction
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Soko Wismar</span> P:
          Cinecentrum
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Kleine Familie, große Baustelle
          </span>{" "}
          R: Holger Haase P.: Magic Flight Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Unverschämtes Glück</span> R:
          Hartmut Schön P: Cinecentrum
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Terra X: Die Spur des Geldes
          </span>{" "}
          P: Cinecentrum
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Er ist wieder da</span> R:
          David Wnendt P: Mythos Film, Constantin Film
        </li>
      </ul>
      <h3 className="ref-year">2013</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Zeit der Zimmerbrände</span> R:
          Vivian Naefe P: Polyphon Südwest
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Tatort – Auf Herz und Niere
          </span>{" "}
          P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Wenn ich du wäre</span> R:
          Vivian Naefe P: H&#38;V Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schönefeld Boulevard</span> R:
          Sylke Enders P: Credofilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Tatort – Kaltstart</span> P:
          Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ein Fall von Liebe</span> P:
          Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110 – Käfer und Prinzessin
          </span>{" "}
          P: Real Film Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Kingdom of Angels</span> R:
          Stuart Croft
        </li>
      </ul>
      <h3 className="ref-year">2012</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Fluss des Lebens – Verloren am Amazonas{" "}
          </span>{" "}
          P: Schiwago Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 101 – Doppeltes Spiel
          </span>{" "}
          P: Studio Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Helden</span> P: Dreamtool
          Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schönefeld Boulevard</span> P:
          Credofilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Dessau Dancers</span> P:
          Boogiefilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Höhlenmensch</span> P:
          Cinecentrum Berlin
        </li>
      </ul>
      <h3 className="ref-year">2011</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Quellen des Lebens</span>{" "}
          R: Oskar Roehler P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 101 – Zwei Brüder
          </span>{" "}
          P: Studio Berlin
        </li>
      </ul>
      <h3 className="ref-year">2010</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Wer ist Hanna?</span> R: Joe
          Wright P: Sechzehnte Babelsberg Film GmbH
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Kennen Sie Ihren Liebhaber
          </span>{" "}
          P: Ziegler Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Tatort – Edel sei der Mensch und gesund
          </span>{" "}
          P: Ophir film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Ausbildung</span> R: Dirk
          Lütter P: Unafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Soko Wismar (mehrere Folgen)
          </span>{" "}
          P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Der Traum vom Fliegen (Vorrecherche)
          </span>{" "}
          P: Novafilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Fremde Heimat</span> R: Martin
          Enlen, Aspekt-Telefilm-Produktion Berlin GmbH
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Flemming 2</span> P: Karlheinz
          Brunnemann GmbH &#38; Co. Produktions KG
        </li>
      </ul>
      <h3 className="ref-year">2009</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die kommenden Tage</span> R:
          Lars Kraume P: Badlands Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Father, Son &#38; Holy Cow
          </span>{" "}
          P: Black Forest Films
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Zivilcourage</span> P: Colonia
          Media Filmproduktion GmbH
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Doc und die Hexe</span> R:
          Vivian Naefe P: Sperl &#38; Schott
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Soko Wismar</span> (mehrere
          Folgen) P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Trau niemals deinem Chef</span>{" "}
          P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Meine verrückte Familie</span>{" "}
          R: Christiane Balthasar P: Moovie the Art of Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Eisfieber</span> Mehrteiler R:
          Peter Keglevic P: Constantin Television
        </li>
      </ul>
      <h3 className="ref-year">2008</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Inglourious Basterds</span> R:
          Quentin Tarantino P: The Weinstein Company
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das weiße Band</span> R:
          Michael Haneke P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Haus und Kind</span> R: Andreas
          Kleinert P: Kineo Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Soko Wismar</span> (mehrere
          Folgen) P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">This is Love</span> R: Mathias
          Glasner P: Badlands Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Assistent II</span> P
          Hofmann &#38; Voges Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Mama kommt</span> R: Isabell
          Kleefeld P: Studio Hamburg
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ein Mann, Ein Fjord</span> R:
          Angelo Colagrossi P: Blu Verde F.V.T.
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Mein Mann, Seine Geliebte und ich
          </span>{" "}
          R: Dagmar Hirtz P: TV 60 Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Assistent</span> P: Hofmann
          &#38; Voges Entertainment
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Frankensteinprojekt</span> P:
          Proton Cinema Budapest
        </li>
      </ul>
      <h3 className="ref-year">2007</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">The Reader</span> R: Stephen
          Daldry P: The Weinstein Company
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Pink</span> R: Rudolf Thome P:
          Moana
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ferrari 49</span> R: Michael
          Klier P: X-Filme
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Vorwärts Immer</span> R: Marco
          Mittelstädt P: Kaminski.Stiehm.Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Flame and Citron</span> R: Ole
          Christian Madsen P: Wüste Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Grummeln</span> R: Rainer
          Kaufmann P: Aspekt Telefilm-Produktion Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Der Mustervater – Allein mit Opa
          </span>{" "}
          P: Roxy Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Soko Wismar</span> (mehrere
          Folgen) P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Geheimnis im Wald</span> R:
          Peter Keglevic P: Cinecentrum Berlin
        </li>
      </ul>
      <h3 className="ref-year">2006</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Krauses Fest</span> P: MaFilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ich begehre</span> P: Vakant
          Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Freundschaft und andere Neurosen
          </span>{" "}
          P: Allmedia Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">A little bit pregnant</span> P:
          UFA Fernsehproduktions
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Soko Wismar</span> (mehrere
          Folgen) P: Cinecentrum Berlin
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Guten Morgen, Herr Grothe
          </span>{" "}
          R: Lars Kraume P: Allmedia Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Yella</span> R: Christian
          Petzold P: Schramm Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Sperling – Der Falke</span> P:
          Polyphon Film- und Fernsehgesellschaft mbH
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das wilde Leben</span> P:
          Exportfilm Bischoff &#38; Co.
        </li>
      </ul>
      <h3 className="ref-year">2005</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">After effect</span> P: bbooks
          av
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Leben mit Hannah</span> P: Una
          Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">FC Venus</span> P: Wüste
          Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Kalter Sommer</span> P:
          Teamworx
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110 – Nie solo sein
          </span>{" "}
          P: Allmedia Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Trio</span> (Pilot) P:
          Grundy UFA
        </li>
      </ul>
      <h3 className="ref-year">2004</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Ein Freund von mir</span> R:
          Sebastian Schipper, P: X-Filme Creative Pool
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Liebe in Saigon</span> P:
          Allmedia Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Polizeiruf 110 – Lilly</span>{" "}
          P: Allmedia Pictures
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die echte Prinzessin</span> P:
          Phoenix Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die letzte Schlacht</span> P:
          Cinecentrum
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Liebenden</span> P: Network
          Movie
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Edel &#38; Starck</span>{" "}
          (mehrere Folgen) P: Phoenix Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Papa und Mama</span> P:
          Medienkontor Movie
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Pommery 2</span> P: Ziegler
          Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">SAP-Trailer</span> P: Goebel
          und Mattes
        </li>
      </ul>
      <h3 className="ref-year">2003</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Polizeiruf 110 – Dettmanns Weite Welt
          </span>{" "}
          P: Dokfilm
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Irgendwas ist immer</span> P:
          Dreamer Joint Venture
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Schatten der Diebe</span>{" "}
          P: Studio TV Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Edel und Stark</span> P: Phönix
          Film
        </li>
      </ul>
      <h3 className="ref-year">2002</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Schatten der Macht</span>{" "}
          (2-Teiler) P: Ziegler Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Nitschewo</span> R: Stefan
          Sarazin P: K5 Film / Bavaria Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Eva Blond2</span> P: spanana TV
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Zuckerbrot</span> P: TeamWorx
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Edel und Stark</span> (mehrere
          Folgen) P: Phönix Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Oswalt Kolle</span> P: Talent
          Network
        </li>
      </ul>
      <h3 className="ref-year">2001</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Hexenherz</span> (2-Teiler) P:
          Multimedia
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Der Verleger</span> (2-Teiler)
          P: Ziegler Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Das Herz in meinem Bauch</span>{" "}
          P: Janus Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">MTV Camp</span> P: Neue
          Sentimental
        </li>
      </ul>
      <h3 className="ref-year">2000</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Suck my Dick</span> R: Oskar
          Roehler P: Helkon Film
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Heidi</span> R: Markus Imboden
          P: Vega Film AG Schweiz
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Love, Peace &#38; Pancake
          </span>{" "}
          R: Roman Kuhn P: @Lounge Entertaiment
        </li>
      </ul>
      <h3 className="ref-year">1999</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Frau 2 und Happyend</span> R:
          Edward Berger, P: Box! Filmproduktion
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">My Toys</span> R: Mark Nunneley
          P: Tracks Film GmbH, 5 TV-Spots
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Traumstücke</span> R: Anette
          Rose P: ZDF Kleines Fernsehspiel
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">
            Einmal Doppel-Knocker-Harris mit Salamander
          </span>{" "}
          P: ZDF Kleines Fernsehspiel
        </li>
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Die Köche</span> R: Sandra
          Nettelbeck P: Pandora Filmproduktion
        </li>
      </ul>
      <h3 className="ref-year">1998</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Absolute Giganten</span> R:
          Sebastian Schipper P: X Filme Creative Pool
        </li>
      </ul>
      <h3 className="ref-year">1997</h3>
      <ul className="ref-list">
        <li className="ref-list__list-item">
          <span className="ref-list__film-title">Silvester Countdown</span> R:
          Oskar Roehler P: Erdbeermundfilm
        </li>
      </ul>
    </div>
  </Layout>
)


export const Head = () => <Seo title="Referenzen" />

export default Referenzen
